body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#hero {
	/* background-image: url("../src/assets/img/homepage/home-banner.jpg"); */
	background-repeat: no-repeat;
	background-position: right top;
	background-attachment: fixed;
	background-size: 100% 100%;
	opacity: 0.85;
	height: 100vh;
}

.header {
	margin-top: 100px;
	position: absolute;
	left: 10%;
	right: 10%;
}

.link,
.link:hover {
	text-decoration: none;
	color: rgb(220, 202, 137);
	margin: 1vw;
}

.color-link,
.color-link:hover {
	color: rgb(182, 96, 73);
}

.nav {
	width: 100%;
}

.navlinks {
	/* position: absolute; */
	list-style: none;
}

#footer {
	position: relative;
	bottom: 0px;
}

/* About component */
#about h2 {
	margin-bottom: 30px;
	font-size: 2rem;
}

.about-section {
	padding: 20px;
	position: relative;
	height: 100vh;
}

#about img {
	width: 100%;
	height: auto;
}

/* skills component */
#skills {
	padding-top: 40px;
}

#skills h4 {
	padding: 5px 0px;
}

#skills p {
	margin-top: 10px;
}

#skills-projects {
	height: 100%;
}
.technology-skills {
	list-style: none;
	overflow: hidden;
	text-align: center;
	padding-left: 0px;
}

.skill {
	display: inline-block;
	padding: 5px 20px;
}

/* project component */
.project {
	padding-bottom: 50px;
}

.project p {
	/* background: #f0ffff; */
	padding: 0px 20px;
}

.project a {
	text-decoration: none;
	color: #000000;
}

.project-image {
	margin-top: 20px;
	height: auto;
	width: 100%;
}

/* Contact */
#contact {
	height: 100vh;
	width: 100vw;
}

.input {
	width: 100%;
	text-align: center;
}

.thank-you {
	height: 100vh;
	width: 100vw;
}
.thank-you h3 {
	text-align: center;
	padding-top: 300px;
}
.thank-you p {
	text-align: center;
}

/* media queries for smaller screens */
@media (max-width: 992px) {
	.about-section {
		height: auto;
	}

	#hero {
		padding: 1px;
		background-image: url("../src/assets/img/homepage/mobile-banner.jpg");
		background-attachment: fixed;
		background-position: 20%;
		background-size: auto 100%;

		color: rgb(165, 175, 179);
	}

	.header {
		left: 0%;
		right: 0%;
	}

	.link,
	.link:hover {
		text-decoration: none;
		color: rgb(165, 175, 179);
	}

	.color-link,
	.color-link:hover {
		color: rgb(182, 96, 73);
	}
}
